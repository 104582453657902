import React, { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const MultiRangeSlider = ({ min, max, onChange }) => {
  // Creating the state variables
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);

  // Creating the refs
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => {
      return Math.round(((value - min) / (max - min)) * 100);
    },
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(+maxValRef.current.value);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minValRef.current, maxValRef.current, getPercent]);
  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(+maxValRef.current.value);
      if (minVal <= maxVal) {
        if (range.current) {
          range.current.style.width = `${maxPercent - minPercent}%`;
        }
      }
    }
  }, [minValRef.current, maxValRef.current, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  return (
    <div className="">
      <input
        type="range"
        min={0}
        max={10000}
        step={50}
        value={minVal}
        ref={minValRef}
        onChange={(event) => {
          const value = Math.min(+event.target.value);
          if (value <= maxVal) {
            setMinVal(value);
            event.target.value = value.toString();
            onChange({ min: value, max: maxVal });
          }
        }}
        className={classnames("thumb thumb--zindex-3", {
          "thumb--zindex-5": minVal > max - 100,
        })}
      />
      <input
        type="range"
        min={0}
        max={10000}
        step={50}
        value={maxVal}
        ref={maxValRef}
        onChange={(event) => {
          const value = Math.max(+event.target.value);
          if (value >= minVal) {
            setMaxVal(value);
            event.target.value = value;
            onChange({ min: minVal, max: value });
          }
        }}
        className="thumb thumb--zindex-4"
      />

      <div className="slider">
        <div className="slider__track" />
        <div className="slider__range" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{minVal}</div>
        <div className="slider__right-value">{maxVal}</div>
      </div>
    </div>
  );
};

// Set the type of each prop
MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
