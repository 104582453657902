import React, { useState, useEffect } from "react";
// import APICall from "../api-endpoints";
import { config } from "../config";
import ApplicationSend from "./assets/application-send.svg";
import Calendar from "./assets/calendar.svg";
import Clock from "./assets/clock.svg";
import Trash from "./assets/trash.svg";
import { useLocation } from "react-router-dom";

function AppStats() {
  const [venueName, setVenueName] = useState("");
  const [venueEmail, setVenueEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [emailData, setEmailData] = useState(Array);
  const [sent, setSent] = useState("");
  const [confirmed, setConfirmed] = useState("");
  const [postponed, setPostponed] = useState("");
  const [declined, setDeclined] = useState("");

  //remove this once logan fixes general
  const location = useLocation();
  let bgColor = "#111111";

  if (location.pathname === "/:id") {
    bgColor = bgColor;
  }

  const handleSubmit = async () => {
    let data = JSON.stringify({
      userName: "testingUser",
      venueEmail: venueEmail,
      userMessage: userMessage,
      venueName: venueName,
    });
    await fetch(`{config.API_ENDPOINT}/sendMail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          console.error(res.ok);
          throw new Error();
        }
        res.json();
      })
      .then((data) => {
        localStorage.setItem("key", "value");
        fetchData("update");
        // return data;
      })
      .catch((err) => {
        return err;
      });
    // await APICall.sendEmail(, {
    // userName: 'testingUser',
    // venueEmail: venueEmail,
    // userMessage: userMessage,
    // venueName: venueName
    // });
    // fetchData('update');
  };

  const fetchData = async (value) => {
    if (value === "check") {
      if (localStorage.getItem("artistData")) {
        let data = JSON.parse(localStorage.getItem("artistData"));
        setEmailData(data["testingUser"].emails);
        let emailSent = await data["testingUser"].emails.filter(
          (emails) => emails.status === "Sent"
        ).length;
        let emailConfirmed = await data["testingUser"].emails.filter(
          (emails) => emails.status === "Confirmed"
        ).length;
        let emailPostponed = await data["testingUser"].emails.filter(
          (emails) => emails.status === "Postponed"
        ).length;
        let emailDeclined = await data["testingUser"].emails.filter(
          (emails) => emails.status === "Declined"
        ).length;

        setSent(emailSent);
        setConfirmed(emailConfirmed);
        setDeclined(emailDeclined);
        setPostponed(emailPostponed);
      }
      return;
    }
    const url = `${config.API_ENDPOINT}/retrieveEmails?userName=testingUser`;
    try {
      const response = await fetch(url);
      const json = await response.json();
      localStorage.setItem("emails", JSON.stringify(json));
      setEmailData(json.emails);
      let emailSent = await json.emails.length;
      let emailConfirmed = await json.emails.filter(
        (emails) => emails.status === "Confirmed"
      ).length;
      let emailPostponed = await json.emails.filter(
        (emails) => emails.status === "Postponed"
      ).length;
      let emailDeclined = await json.emails.filter(
        (emails) => emails.status === "Declined"
      ).length;

      setSent(emailSent);
      setConfirmed(emailConfirmed);
      setDeclined(emailDeclined);
      setPostponed(emailPostponed);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchData("update");
  }, []);

  async function onStatusChange(e, id) {
    e.preventDefault();
    let emailsSent = emailData;
    emailsSent[id].status = e.target.value;

    let emailConfirmed = await emailsSent?.filter(
      (emails) => emails.status === "Confirmed"
    ).length;
    let emailPostponed = await emailsSent?.filter(
      (emails) => emails.status === "Postponed"
    ).length;
    let emailDeclined = await emailsSent?.filter(
      (emails) => emails.status === "Declined"
    ).length;

    setConfirmed(emailConfirmed);
    setDeclined(emailDeclined);
    setPostponed(emailPostponed);
    fetch(
      `${config.API_ENDPOINT}/artist/updateEmailStatus`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emails: emailsSent,
          userName: 'testingUser',
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          console.error(res.ok);
          throw new Error();
        }
        return res.json();
      })
      .then((data) => {
        console.log('success!')
      })
      .catch((err) => {
        return err;
      });
  }

  return (
    //Add on sumbit attribute to form
    <form className="container-fluid">
      <style jsx="true">{`
        body {
          background: ${bgColor};
        }
      `}</style>
      <div className="row mt-5">
        <section className="d-flex align-items-center col bg-ap-light stats_inner-flex mx-2 my-2 rounded-3">
          <img src={ApplicationSend} alt="" className="h-75 w-75 col me-3" />
          <section className="col">
            <label htmlFor="sent">Applications Sent</label>
            <h4 className="mt-3" id="sent">
              {sent}
            </h4>
          </section>
        </section>
        <section className="d-flex align-items-center col bg-ap-light stats_inner-flex mx-2 my-2 rounded-3">
          <img src={Calendar} alt="" className="h-75 w-75 col me-3" />
          <section className="col">
            <label htmlFor="accepted">Applications Confirmed</label>
            <h4 className="mt-3" id="accepted">
              {confirmed}
            </h4>
          </section>
        </section>
        <section className="d-flex align-items-center col bg-ap-light stats_inner-flex mx-2 my-2 rounded-3">
          <img src={Clock} alt="" className="h-75 w-75 col me-3" />
          <section className="col">
            <label htmlFor="postponed">Applications Postponed</label>
            <h4 className="mt-3" id="postponed">
              {postponed}
            </h4>
          </section>
        </section>
        <section className="d-flex align-items-center col bg-ap-light stats_inner-flex mx-2 my-2 rounded-3">
          <img src={Trash} alt="" className="h-75 w-75 col me-3" />
          <section className="col">
            <label htmlFor="declined">Applications Declined</label>
            <h4 id="declined" className="mt-3">
              {declined}
            </h4>
          </section>
        </section>
      </div>

      <div className="message-input rounded-3 mt-5">
        <section className=" row">
          <div className="d-flex flex-column col">
            <label htmlFor="venue-name">Venue Name</label>
            <input
              className="stats-inputs rounded mt-1"
              type="text"
              placeholder="Enter your Name"
              onChange={(e) => setVenueName(e.target.value)}
              id="venue-name"
              value={venueName}
            />
          </div>
          <div className="d-flex flex-column col">
            <label htmlFor="venue-email">Email</label>
            <input
              className="stats-inputs rounded"
              type="text"
              placeholder="Enter your Email"
              onChange={(e) => setVenueEmail(e.target.value)}
              id="venue-email"
              value={venueEmail}
            />
          </div>
        </section>
        <section className="row pt-5">
          <div className="d-flex flex-column col">
            <label htmlFor="user-message">Message</label>
            <input
              className="user-message rounded text-white"
              type="text"
              placeholder="Enter your Message"
              onChange={(e) => setUserMessage(e.target.value)}
              id="user-message"
              value={userMessage}
            />
          </div>
        </section>
        <section className="my-4">
          <button
            className="send-to-venue"
            type="submit"
            onClick={() => handleSubmit()}
          >
            Send to Venue
          </button>
        </section>
      </div>

      <div className="gig-overflow-y">
        <div>
          <h1 className="application-overview">Application Overview</h1>
        </div>
        {/* Return Mapped Data here */}
        {emailData.map((value, index) => {
          return (
            <section
              key={index}
              className="row justify-content-center align-items-center py-2"
            >
              <img className="col-2 status-calendar" src={Calendar} alt="" />
              <p className="col">{value.date}</p>
              <p className="col">{value.venueName}</p>
              <p className="col">{value.emailRecipient}</p>
              <div className="col dropdown">
                <select
                  className="col dropdown btn btn-secondary dropdown-toggle bg-transparent toggled-app-stat"
                  value={value.status}
                  onChange={(e) => onStatusChange(e, index) }
                >
                  <option value={'Sent'} onChange={onStatusChange}>
                    Sent
                  </option>
                  <option value={'Confirmed'} onChange={onStatusChange}>
                    Confirmed
                  </option>
                  <option value={'Postponed'} onChange={onStatusChange}>
                    Postponed
                  </option>
                  <option value={'Declined'} onChange={onStatusChange}>
                    Declined
                  </option>
                </select>
              </div>
            </section>
          );
        })}
      </div>
    </form>
  );
}
export default AppStats;
