import React, { useState } from "react";
import GigtorLogo from "../global-assets/header-logo-with-text.svg";
import LaptopPreview from "../landingpage/assets/laptop-preview.png";
import { useLocation } from "react-router-dom";

function Landingpage() {
  const [emailAddress, setEmailAddress] = useState("");
  const location = useLocation();
  let bgColor = `linear-gradient(180deg, #111111 0%, #000000 0.01%, #2F3E41 87.17%)`;
  let padding = `12px`;

  if (location.pathname === "/giglp") {
    bgColor = bgColor;
    padding = padding;
  }

  function setAddress(e) {
    setEmailAddress(e.target.value);
  }

  return (
    <div className="container">
      <style jsx="true">{`
        body {
          background: ${bgColor};
          padding: ${padding};
        }
      `}</style>
      <div className="row">
        <div className="container img-ya">
          <img src={GigtorLogo} alt="gigtor logo" className="mb-lg-5" />
        </div>
        <div className="col-md-6 mob-2">
          <div className="text-white pe-5 mt-5">
            <h1 className="landingpage-header">
              Get your music to the right people and the right music to you.
            </h1>
            <p className="landingpage-copy py-4">
              Gigtor is here to help the music community. We combine human
              interaction with data, making sure we stay true to our slogan and
              save you a lot of time, hustle, and worries.
            </p>
          </div>
          <div className="input-group mb-3  w-md-100 w-ld-75">
            <input
              type="email"
              required
              className="form-control p-3"
              placeholder="Enter your Email"
              aria-label="Users email"
              aria-describedby="user-email"
              id="user-input"
              onChange={(e) => setAddress(e.target.value)}
              value={emailAddress}
            />
            <div className="input-group-append">
              <span className="input-group-text p-3" id="user-email">
                Get Early Access
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <img
            src={LaptopPreview}
            alt="A laptop, with a preview of the of the Gigtor application on the screen"
            className="w-100 mw-100"
          />
        </div>
      </div>
    </div>
  );
}

export default Landingpage;
