const genres = {
  main: [
    "Pop",
    "Rock",
    "Hip Hop/Rap",
    "Indie",
    "Alternative",
    "Blues",
    "Country",
    "Electronic",
    "Jazz",
    "R&B/Soul",
    "Reggae",
    "Classical",
    "Disco",
    "Punk",
    "Folk",
    "Funk",
    "Metal",
    "Latin",
    "New Age",
    "World",
    "EDM",
    "Gospel",
    "Grunge",
    "Garage",
    "Downtempo",
    "K-Pop",
    "Ska",
    "Reggaeton",
    "Synth-pop",
  ],

  alternative: [
    "Art Punk",
    "Alternative Rock",
    "Britpunk",
    "College Rock",
    "Crossover Thrash",
    "Crust Punk",
    "Emotional Hardcore",
    "Experimental Rock",
    "Folk Punk",
    "Goth / Gothic Rock",
    "Grunge, Hardcore Punk",
    "Hard Rock",
    "Indie Rock",
    "Lo-fi",
    "Musique Concrète",
    "New Wave",
    "Progressive Rock",
    "Punk",
    "Shoegaze",
    "Steampunk",
  ],

  childrensMusic: ["Lullabies", "Sing-Along", "Stories"],

  classical: [
    "Avant-Garde",
    "Ballet, Baroque",
    "Cantata",
    "Chamber Music",
    "String Quartet",
    "Chant",
    "Choral",
    "Classical Crossover",
    "Concerto",
    "Concerto Grosso",
    "Contemporary Classical",
    "Early Music",
    "Expressionist",
    "High Classical",
    "Impressionist",
    "Mass Requiem",
    "Medieval",
    "Minimalism",
    "Modern Composition",
    "Modern Classical",
    "Opera",
    "Oratorio",
    "Orchestral",
    "Organum",
    "Renaissance",
    "Romantic (early period)",
    "Romantic (later period)",
    "Sonata",
    "Symphonic",
    "Symphony",
    "Wedding Music",
  ],

  country: [
    "Alternative Country",
    "Americana",
    "Australian Country",
    "Bakersfield Sound",
    "Bluegrass",
    "Progressive Bluegrass",
    "Reactionary Bluegrass",
    "Blues Country",
    "Cajun Fiddle Tunes",
    "Christian Country",
    "Classic Country",
    "Close Harmony",
    "Contemporary Bluegrass",
    "Contemporary Country",
    "Country Gospel",
    "Country Pop",
    "Country Rap",
    "Country Rock",
    "Country Soul",
    "Cowboy / Western",
    "Cowpunk",
    "Dansband",
    "Honky Tonk",
    "Franco-Country",
    "Gulf and Western",
    "Hellbilly Music",
    "Honky Tonk",
    "Instrumental Country",
    "Lubbock Sound",
    "Nashville Sound",
    "Neotraditional Country",
    "Outlaw Country",
    "Progressive",
    "Psychobilly / Punkabilly",
    "Red Dirt",
    "Sertanejo",
    "Texas County",
    "Traditional Bluegrass",
    "Traditional Country",
    "Truck-Driving Country",
    "Urban Cowboy",
    "Western Swing, Zydeco",
  ],
};

export default genres;
