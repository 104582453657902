import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import MultiRangeSlider from "./multi-range";
import SaveAndContinue from "./save-and-continue-btns";

const Requirements = () => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(10000);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [venue, setVenue] = useState([]);
  const [toggle, setToggleValue] = useState("");
  const [outletUrl, setOutletUrl] = useOutletContext();
  const [artistMinimum, setArtistMinimum] = useState(Number);
  const [artistRange, setArtistRange] = useState({ min: 0, max: 10000 });

  function toggleSelection(option) {
    setVenue((prevVenue) => {
      if (prevVenue.includes(option)) {
        return prevVenue.filter((selectedOption) => selectedOption !== option);
      } else {
        return [...prevVenue, option];
      }
    });
  }

  useEffect(() => {
    setOutletUrl(window.location.href);
  }, [setOutletUrl]);

  useEffect(() => {
    setMin(artistMinimum);
  }, [artistMinimum]);

  return (
    <form className="onbd__form">
      <section className="onbd__hero">
        <h1 className="onbd__hero__h1">Requirements</h1>
        <p className="onbd__hero__p">
          Please give us information about your requirements.
        </p>
      </section>

      <div className="req--flex">
        <section className="req__slider__container">
          <p className="req__slider">Compensation</p>
        </section>
        <section className="req__toggle">
          <button
            className={
              toggle === "Artist"
                ? "active requirements__toggles"
                : "requirements__toggles"
            }
            value="Artist"
            onClick={(e) => {
              e.preventDefault();
              setToggleValue(e.currentTarget.value);
              setArtistMinimum(min);
              setArtistRange({ min: min, max: max });
            }}
          >
            <span>Minimun</span>
          </button>

          <button
            className={
              toggle === "Venue"
                ? "active requirements__toggles"
                : "requirements__toggles"
            }
            aria-label="Venue"
            value="Venue"
            onClick={(e) => {
              e.preventDefault();
              setToggleValue(e.currentTarget.value);
              setMin(artistMinimum);
              setMax(artistRange.max);
            }}
          >
            <span>Range</span>
          </button>
        </section>
      </div>

      {toggle === "Artist" ? (
        <div className="req__options">
          <label>Minimum</label>
          <input
            type="number"
            value={artistMinimum}
            onChange={(e) => {
              setArtistMinimum(parseInt(e.target.value));
              setMin(parseInt(e.target.value));
            }}
          />
        </div>
      ) : (
        <MultiRangeSlider
          min={artistRange.min}
          max={artistRange.max}
          step={50}
          onChange={({ min, max }) => {
            setMin(min);
            setMax(max);
          }}
        />
      )}

      <div className="req__options">
        Payment Method
        <select
          className="onbd__input onbd__input--pt"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
        >
          <option defaultValue="default" className="onbd__input--lightcolor">
            Choose Payment Method
          </option>
          <option value="Card">Card</option>
          <option value="Cash">Cash</option>
          <option value="Bank Transfer">Bank Transfer</option>
          <option value="Crypto">Crypto</option>
        </select>
      </div>

      <div className="req__options">
        In what type of venues do you prefer to perform
        <select
          className="onbd__input onbd__input--pt"
          multiple={true}
          value={venue}
          onChange={(e) => {
            const selected = [...e.target.options].filter(
              (option) => option.selected
            );
            selected.forEach((option) => toggleSelection(option.value));
          }}
        >
          <option defaultValue="default">Choose Venue</option>
          <option value="Stadium">Stadium</option>
          <option value="House">House</option>
          <option value="Bathroom Stall (Stinky 🤢)">Bathroom Stall</option>
          <option value="A groovy bar">A groovy bar</option>
          <option value="Logans House (Partay)">Logan's House</option>
        </select>
      </div>

      <SaveAndContinue
        endpoint="artist/requirements"
        info={{
          min,
          max,
          paymentMethod,
          venue,
          venue: venue,
        }}
        navigate=""
      />
    </form>
  );
};

export default Requirements;
